<template>
  <layout width="420">
    <template slot="header">
      {{ title }}
    </template>

    <template>
      <validation-observer ref="form">
        <v-form>
          <validation-provider
            v-if="!edit"
            v-slot="{ errors }"
            name="Object type"
            rules="required"
          >
            <v-select
              v-model="state.schemaId"
              :items="types"
              item-text="name"
              :error-messages="errors"
              item-value="id"
              label="Object type"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="name" rules="required">
            <v-text-field
              v-model="state.name"
              label="Name"
              :error-messages="errors"
            />
          </validation-provider>
          <v-select
            v-model="state.positionGeotagId.value"
            :items="geotagList"
            item-text="name"
            item-value="id"
            label="Geotag"
          />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          />
          <select-chips
            v-model="localObjectGroups"
            multiple
            :items="objectGroups"
            label="Object groups"
            item-text="name"
            item-value="id"
            chips
            class="ma-0"
            @drop-item="handleDropChip"
          />
          <image-uploader
            :src="imageUrl"
            label="Photo"
            @change="handleChangeImage"
            @clear="handleClearImage"
          />
          <form-subheader title="Options" />
          <form-switcher v-model="state.enabled" title="Enabled" />
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="isLoading"
        @click.stop="exec"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import SelectChips from '@/components/_base/SelectChips';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectService } from '@/modules/objects/api';
import { imageService, objectPropertyService } from '@/modules/common/api';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { mediaClient } from '@/provider';
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { prepareObject } from '@/provider/utils';
import { geotagservice } from '@/modules/geotags/api';
import { useTypes } from '@/modules/object-types/compositions/types';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'ObjectEdit',
  components: {
    Layout,
    SelectChips,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    objectId: {
      type: [Number, String, undefined],
      default: undefined
    },
    defaultLonLat: {
      type: Array,
      default: () => [0, 0]
    }
  },
  setup(props, { emit, root }) {
    const state = ref({
      name: '',
      description: '',
      enabled: true,
      schemaId: '',
      objectGroups: [],
      infoImage: {
        id: '',
        key: '',
        value: ''
      },
      positionGeotagId: {
        id: '',
        key: '',
        value: ''
      },
      positionPoint: {
        id: ''
      }
    });
    let savedPositionGeotagId = {
      id: null,
      value: null
    };
    let savedInfoImage = {
      id: null,
      value: null
    };
    const { objectGroupList } = useObjectGroups();
    const { list: geotagList, currentGeotagExceptAllItem } = useGeotags();
    const { list } = useTypes();

    const types = computed(() => list.value.filter(type => type.enabled));

    const localObjectGroups = ref([]);
    const form = ref(null);
    const objectGroups = ref(objectGroupList.value);
    const edit = computed(() => !!props.objectId);
    const title = edit.value ? 'Edit object' : 'Create object';
    const isLoading = ref(false);

    const selectedGeotag = computed(() =>
      geotagList.value?.find(i => i.id === state.value?.positionGeotagId?.value)
    );

    const submit = async () => {
      if (isLoading.value) return;
      form.value.validate().then(async success => {
        if (!success) {
          return;
        }

        try {
          isLoading.value = true;

          let objectId = props.objectId;
          if (!edit.value) {
            const payload = {
              name: state.value.name,
              description: state.value.description,
              enabled: state.value.enabled,
              schemaId: state.value.schemaId
            };
            const newObject = prepareObject(
              (await objectService.create(payload)).data?.createObject?.object
            );
            objectId = newObject.id;
            state.value.positionPoint.id = newObject.positionPoint.id;
            state.value.infoImage.id = newObject.infoImage.id;
            state.value.positionGeotagId.id = newObject.positionGeotagId.id;

            const [lon, lat] = props.defaultLonLat;
            const alt = selectedGeotag.value?.positionBaseAltitude || 0;
            await objectPropertyService.update(state.value.positionPoint.id, {
              value: {
                lon,
                lat,
                alt
              }
            });
          } else {
            await objectService.update(objectId, {
              name: state.value.name,
              description: state.value.description || '',
              enabled: state.value.enabled
            });
          }

          const updateImageProperty = objectPropertyService.update(
            state.value.infoImage.id,
            { value: state.value.infoImage.value }
          );
          const updateGeotagProperty = objectPropertyService.update(
            state.value.positionGeotagId.id,
            { value: state.value.positionGeotagId.value }
          );
          geotagservice.updateLinkToObject(
            objectId,
            savedPositionGeotagId.value,
            state.value.positionGeotagId.value
          );
          const groupsForUnlink = state.value.objectGroups.filter(
            group =>
              !localObjectGroups.value.find(
                currentGroup => currentGroup.id === group.object1.id
              )
          );
          await Promise.all(
            groupsForUnlink.map(group => objectService.unlink(group.id))
          );
          const groupsForLink = localObjectGroups.value.filter(
            currentGroup =>
              !state.value.objectGroups.find(
                prevGroup => prevGroup.object1.id === currentGroup.id
              )
          );

          if (
            state.value.infoImage.value &&
            state.value.infoImage.value !== savedInfoImage.value
          ) {
            objectService.link(objectId, state.value.infoImage.value);
          }

          await Promise.all(
            groupsForLink.map(group => objectService.link(group.id, objectId))
          );

          await Promise.all([updateImageProperty, updateGeotagProperty]);

          if (!edit.value) {
            root.$router.push({
              name: 'object_card',
              params: {
                objectId
              }
            });
          }

          emit('close');
        } finally {
          isLoading.value = false;
        }
      });
    };

    const imageUrl = computed(() =>
      mediaClient.getImageUrl(state.value.infoImage.value)
    );

    const handleChangeImage = async e => {
      state.value.infoImage.value = await imageService.upload(
        e.target.files[0]
      );
    };

    const handleClearImage = () => {
      state.value.infoImage.value = '';
    };

    const { exec } = usePromise(submit, true);

    onMounted(async () => {
      try {
        if (edit.value) {
          state.value = await objectService.fetch(props.objectId);
          localObjectGroups.value = state.value.objectGroups.map(
            object => object.object1
          );
          savedInfoImage = { ...state.value.infoImage };
          savedPositionGeotagId = { ...state.value.positionGeotagId };
        } else {
          state.value.positionGeotagId.value =
            currentGeotagExceptAllItem.value.id;
          // set object group if we create object from some group
          const { groupId } = root.$route.params;
          if (groupId) {
            localObjectGroups.value = objectGroups.value.filter(
              group => group.id === groupId
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    const handleDropChip = id => {
      localObjectGroups.value.splice(
        localObjectGroups.value.findIndex(i => i.id === id),
        1
      );
    };

    return {
      state,
      exec,
      isLoading,
      handleDropChip,
      localObjectGroups,
      objectGroups,
      imageUrl,
      handleChangeImage,
      handleClearImage,
      form,
      geotagList,
      edit,
      title,
      types
    };
  }
};
</script>
